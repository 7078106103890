<template>
  <el-upload
    class="upload-demo"
    action
    :show-file-list="false"
    multiple
    drag
    :disabled="disabled"
    :http-request="handleUploadFile"
  >
    <img
      v-if="imageUrl"
      :src="path+imageUrl"
      class="avatar"
    >
    <template v-else>
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em>
      </div>
    </template>

  </el-upload>
</template>

<script>
import { uploadFile } from '@/api/file'

export default {
  name: 'uploadIndex',
  props: {
    imageUrl: {
      type: String
    },
    disabled: {
      type: Boolean

    }
  },
  data () {
    return {
        path: process.env.VUE_APP_URL+this.UploadPath
    }
  },
  mounted () {
  },
  methods: {
    handleUploadFile (file) {
      const formData = new FormData()
      formData.append('file', file.file)
      uploadFile(formData).then(res => {
        if (res.code === '000000') {
          this.$emit('imgVal', res.data.importFileName)
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.el-upload__tip {
  margin-top: -7px;
}
.avatar{
  width: 360px;
  height: 180px;
}
// .userList .banners .el-upload-dragger {
//   width: 250px;
//   height: 125px;
// }
</style>
