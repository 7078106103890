import service from "../service.js";


export const serchDeviceKeepLog = params => {
    return service({
      method: 'POST',
      url: '/keepLog/serchDeviceKeepLog',
      params
    })
}

export const addDeviceKeepLog = (data) => {
    return service({
      method: 'POST',
      url: '/keepLog/addDeviceKeepLog',
      data
    })
  }
  
  export const updateDeviceKeepLog = (data) => {
    return service({
      method: 'POST',
      url: '/keepLog/updateDeviceKeepLog',
      data
    })
  }
  
  export const queryKeepLogInfo = (id) => {
    return service({
      method: 'GET',
      url: '/keepLog/queryKeepLogInfo',
      params: {
        id
      }
    })
  }

  export const deviceStatusList = [{ code: '00', name: '正常' }, { code: '01', name: '部件损坏无法正常使用' }, { code: '02', name: '部件损坏可以继续使用' }, { code: '03', name: '附件损坏无法正常使用' }, { code: '04', name: '附件损坏可以继续使用' }, { code: '05', name: '精度不准无法正常使用' }]

  export const powerTimeList = [{ code: '00', name: '2小时' }, { code: '01', name: '1小时' }, { code: '02', name: '0.5小时' }, { code: '03', name: '0.25小时' }, { code: '05', name: '不需要通电' }]

  export const keepRules = {
    isGuiseGood: [{required: true}],
    isPzGood: [{required: true}],
    isTdzjNormal: [{required: true}],
    isNeedCharge: [{required: true}],
    deviceStatus: [{required: true, message: '请选择仪器仪表状态！'}],
    powerTime: [{required: true, message: '请选择通电时长！'}],
    isQjccAchieve: [{required: true}],
    isChargeAchieve: [{required: true}],
    keepDate: [{required: true, message: '请选择保养日期！'}],
    keepPersonnel: [{required: true, message: '请填写保养人员！'}],
}


